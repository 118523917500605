// Urgent polyfills (needed before first render)
///////////////////////////////////////////////////////////////////////////////////

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
  }
}

// Non-urgent polyfills and scripts (needed after first render)
///////////////////////////////////////////////////////////////////////////////////

export const onInitialClientRender = () => {
  // A11Y: Detect keyboard vs. mouse vs. touch input (for focus styling)
  if (!loadjs.isDefined(`what-input`)) {
    loadjs(`https://unpkg.com/what-input@5.1.3/dist/what-input.js`, `what-input`)
  }

  // Fetch polyfill (IE)
  if (!loadjs.isDefined(`unfetch`)) {
    if (typeof window.fetch === `undefined`) {
      loadjs(`https://unpkg.com/unfetch/polyfill`, `unfetch`)
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////

import loadjs from 'loadjs'
